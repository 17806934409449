body {
  min-height: 100%
}

html {
  height: initial !important
}

.dimmed {
  position: relative;
}

.dimmed:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.c3-line {
  stroke-width: 3px !important;
}

.c3-target-dashedstyle{
  stroke-dasharray: 5,5; 
}
